import Vue from 'vue';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { mapMutations } from "vuex";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Notifications from 'vue-notification';
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Notifications);
Vue.use(Vue2TouchEvents);



Vue.mixin({
  methods: {
    ...mapMutations(["SET_LOCK_KEY", "SET_LOCK_KEY_MAX", "SET_RELEASE_TRIED", "SET_LOCK_CREATED", "SET_SETTINGS_TIME_BETWEEN_TRYING"]),
    ajaxBoilerplate(method, urlAction, data) {
      return {
        method: method,
        url: `/api/${urlAction}`,
        headers: {
          "access-token": localStorage.getItem("jwt"),
        },
        data: data
      };
    },
    alertSuccess(title, text = "") {
      this.$notify({
        group: "grulet",
        title: title,
        text: text,
        position: "top",
        type: "success",
        duration: 7000
      });
    },
    alertError(title, text = "") {
      this.$notify({
        group: "grulet",
        title: title,
        text: text,
        position: "top",
        type: "error",
        duration: 7000
      });
    },
    calculateTimeLocked(fromDate, toDate = false) {
      fromDate = new Date(fromDate);
      toDate = toDate ? new Date(toDate) : new Date();

      let seconds = Math.floor((toDate - fromDate) / 1000);
      const onlySecounds = seconds;
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      days = days < 10 ? `0${days}` : days;
      hours = hours - days * 24;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes - days * 24 * 60 - hours * 60;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return {
        'days' : days,
        'hours' : hours,
        'minutes' : minutes,
        'seconds' : seconds,
        'onlySecounds' : onlySecounds
      };
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async checkSession() {
      if (localStorage.getItem("jwt")) {
        try {
          await axios(this.ajaxBoilerplate("get", "check-session", null));
          return true;
        } catch (error) {
          localStorage.removeItem("user");
          localStorage.removeItem("jwt");
          this.$router.push("/login");
          return false;
        }
      } else {
        if(this.$router.history.current.fullPath !== "/login") {
          this.$router.push("/login");
        } 
        return false;
      }
    },
    async setLock() {
      try {
        const result = await axios(
          this.ajaxBoilerplate("get", "get-lock", null)
        );
        console.log(result);
        this.SET_LOCK_KEY(result.data.key);
        this.SET_RELEASE_TRIED(result.data.lastTry);
        this.SET_LOCK_CREATED(result.data.createdAt);
        this.SET_LOCK_KEY_MAX(result.data.keyMax);
      } catch (error) {
        console.log(error);
      }
    },
    async setSettings() {
      try {
        const result = await axios(
          this.ajaxBoilerplate("get", "get-settings", null)
        );
        this.SET_SETTINGS_TIME_BETWEEN_TRYING(result.data.settings.timeBetweenTrying);
      } catch (error) {
        console.log(error);
      }
    }
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')