<template>
  <div>
    <div v-if="showData">
      <!-- <div
        v-bind:class="sLockKey ? 'lock-status-locked' : 'lock-status-free'"
      ></div> -->

      <Lock v-if="!sLockKey" />
      <Release @isReleased="onReleasd" v-if="sLockKey" />

      <div v-if="isFree" class="text-center">
        <img src="/img/free-chat.svg" class="is-free-img" alt="" srcset="" />
        <div class="mt-5">
          Time locked (dd:hh:mm): {{ timeLocked.days }}:{{
            timeLocked.hours
          }}:{{ timeLocked.minutes }}
        </div>
        <div v-if="isRecord" class="mt-5">
          <div class="alert alert-success" role="alert">
            <h4 class="alert-heading">New record!</h4>
            <p>
              You broke your old record with (dd:hh:mm): {{ oldTime.days }}:{{
                oldTime.hours
              }}:{{ oldTime.minutes }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Release from "@/components/Release";
import Lock from "@/components/Lock";
export default {
  name: "LockRelease",
  components: {
    Lock,
    Release,
  },
  data() {
    return {
      showData: false,
      isFree: false,
      timeLocked: {},
      isRecord: false,
      oldTime: {},
    };
  },
  watch: {
    dataReady: function (val) {
      if (this.showData === false && val === true) {
        this.showData = true;
      }
    },
  },
  created() {
    if (this.dataReady) {
      this.showData = true;
    }
  },
  destroyed() {
    if (this.isFree) {
      this.REMOVE_LOCK_KEY();
    }
  },
  computed: {
    ...mapGetters(["sLockKey", "dataReady"]),
  },
  methods: {
    ...mapMutations(["REMOVE_LOCK_KEY"]),
    async onReleasd(val) {
      this.timeLocked = val;
      this.checkIfRecord();
      this.isFree = true;
    },
    checkIfRecord() {
      if (!this.timeLocked.archiveMaxTime) return;

      if (this.timeLocked.archiveMaxTime < this.timeLocked.onlySecounds) {
        this.isRecord = true;

        let seconds =
          this.timeLocked.onlySecounds - this.timeLocked.archiveMaxTime;
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        days = days < 10 ? `0${days}` : days;
        hours = hours - days * 24;
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes - days * 24 * 60 - hours * 60;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.oldTime.days = days;
        this.oldTime.hours = hours;
        this.oldTime.minutes = minutes;
      }
    },
  },
};
</script>

<style>
.is-free-img {
  width: 100%;
}

/* .lock-status-locked {
  background-image: url(/img/grulet_locked.svg);
  background-repeat: no-repeat;
  width: auto;
  height: 100px;
  background-size: auto 100px;
  background-position: center;
} */
</style>