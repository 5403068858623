<template>
  <div id="app">
    <notifications group="grulet" />
    <div class="header">
      <router-link class="navbar-brand" to="/"
        >
        <div
        v-bind:class="sLockKey ? 'lock-status-locked' : 'lock-status-free'"
      ></div>
        
        </router-link>
    </div>
    <div class="mt-3 container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
  },
  async created() {
    await this.checkSession();
    await this.setLock();
    await this.setSettings();
    this.SET_DATA_READY(true);
  },
  computed: {
    ...mapGetters(["sLockKey"]),
  },
  methods: {
    ...mapMutations(["SET_DATA_READY"]),
  },
};
</script>


<style>
html, body {
  /* background-color: #21244A; */
  background-color: #F5F5F5;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* background-color: #21244A; */
  background-color: #F5F5F5;
}

.btn {
  width: 100%;
}
.header {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #ccc; */
}

.navbar-brand {
  margin-right: auto!important;
}

.lock-status-locked {
  background-image: url(/img/grulet_wheel_locked.svg);
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
  background-size: auto 150px;
  background-position: center;
}

.lock-status-free {
  background-image: url(/img/grulet_wheel_unlocked.svg);
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
  background-size: auto 150px;
  background-position: center;
}

.alert {
  border-radius: 0!important;
}
</style>
