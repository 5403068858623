<template>
  <div class="img-background">
    <a @click="$router.go(-1)" class="go-back"> </a>

    <div v-if="!imgData" class="spinner-wrapper mt-5">
      <div class="spinner-grow text-success" role="status"></div>
    </div>
    
    <div class="imgbox" v-bind:class="{ 'padding-box': !zoomImg }" @click="onClick">
      <img
        class="center-fit"
        v-bind:class="{ zoom: zoomImg }"
        v-if="imgData"
        v-bind:src="imgData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "RandomImage",
  data() {
    return {
      imgData: false,
      delay: 500,
      clicks: 0,
      timer: null,
      zoomImg: false,
    };
  },
  async created() {
    await this.getRandomImg();
  },
  computed: {
    ...mapGetters(["sLockKey"]),
  },
  methods: {
    onClick() {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          console.log("single");
          this.clicks = 0;
          this.zoomImg = false;
          this.getRandomImg();
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        console.log("double");
        this.zoomImg = !this.zoomImg;
        this.clicks = 0;
      }
    },
    async getRandomImg() {
      const conf = {
        method: "get",
        url: `/api/random-img`,
        responseType: "blob",
        headers: {
          "access-token": localStorage.getItem("jwt"),
        },
      };
      this.imgData = false;
      try {
        const result = await axios(conf);
        this.imgData = URL.createObjectURL(result.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
/* .img-background {
  background-color: #000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
} */
.imgbox {
  display: grid;
  /* height: 100vh; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: #000;
}

.padding-box {
  padding: 10px;
}

.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
  background-color: #fff;
  padding: 10px;
}

.zoom {
  max-width: fit-content;
  max-height: fit-content;
  transition: all 0.5s ease-in-out;
}

.spinner-wrapper {
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 45%;
  transform: translateY(-50%);
  left: 50%;
}

.spinner-grow.text-success {
  color: #006600 !important;
}

.go-back {
  background-image: url("/img/icons/go-back.svg");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
  background-size: 40px;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
  transition: background-image 0.5s ease;
}

.go-back:hover {
  background-image: url("/img/icons/go-back-hover.svg");
}
</style>
