import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import LockRelease from '../views/LockRelease';
import RandomImage from '../views/RandomImage';
import Settings from '../views/Settings';
import Home from '../views/Home.vue'
import store from '../store/index.js'
import axios from 'axios';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lock-release',
    name: 'LockRelease',
    component: LockRelease,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/random-image',
    name: 'RandomImage',
    component: RandomImage,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      });
    } else {
      try {
        const result = await axios.get("/api/verify", {
          "headers": {
            "access-token": localStorage.getItem('jwt')
          }
        });
        next();
      } catch (error) {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        next({
          path: '/login',
          params: {
            nextUrl: to.fullPath
          }
        });
      }
    }
  } else {
    next();
  }
});

export default router;