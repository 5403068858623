<template>
  <div class="home">
    <div class="mb-3">
      <div v-if="sLockKey">
        <div class="time-locked-wrapper">
          <h3 class="locked-heading">
            Time locked
          </h3>
          <div class="time-container">
            <div class="num">{{ timeLocked.days }}</div>
            <div class="num">{{ timeLocked.hours }}</div>
            <div class="num">{{timeLocked.minutes}}</div>
          </div>
          <div class="time-container">
            <div class="txt">Days</div>
            <div class="txt">Hours</div>
            <div class="txt">Minutes</div>
          </div>
        </div>
      </div>
    </div>
    <router-link
      class="nav-link btn btn-secondary btn-lg btn-block"
      to="/lock-release"
      >Lock &amp; Release</router-link
    >

    <router-link
      class="nav-link btn btn-secondary btn-lg btn-block mt-4"
      to="/settings"
      >Settings</router-link
    >
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      showData: false,
      calculateInterval: 0,
      timeLocked: {
        hours: 0,
        minutes: 0,
        seconds: 0,
        onlySecounds: 0,
        archiveMaxTime: false,
      },
    };
  },
  watch: {
    dataReady: function(val) {
      if (this.showData === false && val === true) {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    clearInterval(this.calculateInterval);
  },
  computed: {
    ...mapGetters(["sLockKey", "lockCreated", "dataReady"]),
  },
  components: {},
  methods: {
    computeTimeLocked() {
      const tl = this.calculateTimeLocked(this.lockCreated);
      this.timeLocked.days = tl.days;
      this.timeLocked.hours = tl.hours;
      this.timeLocked.minutes = tl.minutes;
      this.timeLocked.seconds = tl.seconds;
      this.timeLocked.onlySecounds = tl.onlySecounds;
    },
    fetchData() {
      if (this.sLockKey) {
        this.showData = true;
        this.computeTimeLocked();
        this.calculateInterval = setInterval(() => {
          this.computeTimeLocked();
        }, 60000);
      }
    },
  },
};
</script>
<style scoped>
.locked {
  color: #c40d0d;
}

.free {
  color: #006600;
}

.time-locked-wrapper {
  background-color: #6c757d;
  padding-top: 25px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-radius: .3rem;
}

.locked-heading {
  text-align: center;
  margin-bottom: 25px;
  color: #fff;
}

.time-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.time-container .num,
.time-container .txt {
  order: 3;
  flex-basis: 80px;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

.time-container .txt  {
  margin-top: 5px;
}

.time-container .num {
  background-color: #484d52;
  padding: 10px;
}
</style>
