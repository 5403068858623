<template>
  <div>
    <form>
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          v-model="username"
          required
          class="form-control"
          id="username"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          required
        />
      </div>
      <button type="submit" class="btn btn-secondary" @click="handleSubmit">
        Login
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        try {
          const response = await axios.post("/auth/login", {
            username: this.username,
            password: this.password,
          });
          if (response.data.token !== null) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("jwt", response.data.token);
            await this.setLock();
            await this.setSettings();
            this.$emit("loggedIn");
            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl);
            } else {
              this.$router.push("/");
            }
          }
        } catch (error) {
          console.error(error.response);
        }
      }
    },
  },
};
</script>