<template>
  <div class="">
    <div class="form-row">
      <div class="col-12">
        <label for="releasekey"
          >Release key between 1 and choosen number below</label
        >
        <input
          id="releasekey"
          type="number"
          min="1"
          v-model="releasekeyMax"
          class="form-control"
          placeholder="Release Key"
        />
      </div>
    </div>

    <div class="mt-5">
      Probability to get this key for each try will be {{probability}}%
    </div>

    <button
      type="button"
      v-on:click="submit()"
      class="btn btn-outline-secondary btn-lg btn-block mt-5"
    >
      Generate release key
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "Lock",
  data() {
    return {
      releasekeyMax: 10,
    };
  },
  computed: {
    probability() {
      return ((1 / parseInt(this.releasekeyMax)) * 100).toFixed(2);
    }
  },
  methods: {
    ...mapMutations(["SET_LOCK_KEY", "SET_LOCK_KEY_MAX", "SET_LOCK_CREATED", "SET_RELEASE_TRIED"]),
    async submit() {
      if (this.releasekeyMax > 0) {
        await this.checkSession();
        const releasekey = this.getRandomInt(1, parseInt(this.releasekeyMax));
        try {
          const result = await axios(
            this.ajaxBoilerplate("post", "new-lock", {
              releasekey: parseInt(releasekey),
              releasekeyMax: parseInt(this.releasekeyMax)
            })
          );
          this.SET_LOCK_KEY(parseInt(result.data.lockKey));
          this.SET_LOCK_KEY_MAX(parseInt(result.data.lockKeyMax));
          this.SET_LOCK_CREATED(result.data.createdAt);
          this.SET_RELEASE_TRIED(result.data.lastTry);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
</style>