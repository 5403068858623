import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lock: {
      key: false,
      keyMax: false,
      created: false,
      lastReleaseTried: false
    },
    userSettings: {
      timeBetweenTrying: false
    },
    dataReady: false
  },
  getters: {
    sLockKey(state) {
      return state.lock.key;
    },
    keyMax(state) {
      return state.lock.keyMax;
    },
    sUserSettingsTimeBetweenTrying(state) {
      return state.userSettings.timeBetweenTrying;
    },
    lastReleaseTried(state) {
      return state.lock.lastReleaseTried;
    },
    lockCreated(state) {
      return state.lock.created;
    },
    dataReady(state) {
      return state.dataReady;
    }
  },
  mutations: {
    SET_JWT(state, jwt) {
      state.jwt = jwt;
    },
    SET_DATA_READY(state, dataReady) {
      state.dataReady = dataReady;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LOCK_KEY(state, lockKey) {
      state.lock.key = lockKey;
    },
    SET_LOCK_KEY_MAX(state, lockKeyMax) {
      state.lock.keyMax = lockKeyMax;
    },
    SET_LOCK_CREATED(state, lockCreated) {
      state.lock.created = lockCreated;
    },
    SET_RELEASE_TRIED(state, releaseTried) {
      state.lock.lastReleaseTried = releaseTried;
    },
    REMOVE_LOCK_KEY(state, remove = true) {
      state.lock = {
        key: false,
        keyMax: false,
        created: false,
        lastReleaseTried: false
      };
    },
    SET_SETTINGS_TIME_BETWEEN_TRYING(state, timeBetweenTrying) {
      timeBetweenTrying = parseInt(timeBetweenTrying);
      state.userSettings.timeBetweenTrying = timeBetweenTrying;
    }
  },
  actions: {
  },
  modules: {
  }
});
