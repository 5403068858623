<template>
  <div>
    <div v-if="nextTry && !attemptValue" class="mt-3">
      <h3 class="text-center">Time to next try</h3>
      <flip-countdown
        v-bind:deadline="nextTry"
        @timeElapsed="timeElapsedHandler"
      ></flip-countdown>

      <router-link
        v-if="sLockKey"
        class="nav-link btn btn-secondary btn-lg btn-block mt-5"
        to="/random-image"
        >Random image</router-link
      >
    </div>

    <div v-if="showSpinner" class="text-center mb-3 mt-3 spinner-wrapper">
      <!-- <img src="img/spinner.gif" alt="" /> -->
      <div class="text-center">
        Computing random number between 1 and {{ keyMax }}
      </div>
    </div>

    <div v-if="!nextTry" class="">
      <div class="text-center">Release key: {{ sLockKey }}</div>

      <div v-if="attemptValue" class="text-center mt-3">
        <div>Result is: {{ attemptValue }}</div>

        <div
          v-if="attemptValue !== sLockKey"
          class="alert alert-danger mt-5"
          role="alert"
        >
          <strong>To bad.</strong> Have to stay locked for another
          {{ sUserSettingsTimeBetweenTrying }} hours.
        </div>
      </div>
      <button
        v-if="!attemptValue"
        type="button"
        @click="tryToGetFree()"
        v-bind:disabled="showSpinner"
        class="btn btn-outline-secondary btn-lg btn-block mt-5"
      >
        Try to get free
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "Release",
  components: {
    FlipCountdown,
  },
  data() {
    return {
      nextTry: false,
      attemptValue: false,
      timeLocked: {
        hours: 0,
        minutes: 0,
        seconds: 0,
        onlySecounds: 0,
        archiveMaxTime: false,
      },
      showSpinner: false,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      "sLockKey",
      "keyMax",
      "lastReleaseTried",
      "lockCreated",
      "sUserSettingsTimeBetweenTrying",
    ]),
  },
  methods: {
    fetchData() {
      let dateLastTry = new Date(this.lastReleaseTried);
      let dateNextTry = new Date();

      dateNextTry.setTime(
        dateLastTry.getTime() +
          this.sUserSettingsTimeBetweenTrying * 60 * 60 * 1000
      );

      let today = new Date();
      const diff = (dateNextTry.getTime() - today.getTime()) / 60 / 60 / 1000;
      if (diff > 0) {
        this.nextTry = dateNextTry.toString();
      }
    },
    ...mapMutations(["REMOVE_LOCK_KEY", "SET_RELEASE_TRIED"]),
    timeElapsedHandler() {
      this.nextTry = false;
      this.alertSuccess("Yay! You can try to get free...");
    },
    async tryToGetFree() {
      const sessionOK = await this.checkSession();

      if (!sessionOK) return;

      this.showSpinner = true;

      setTimeout(async () => {
        this.showSpinner = false;
        this.attemptValue = this.getRandomInt(1, this.keyMax);

        if (this.attemptValue === this.sLockKey) {
          const maxTimeLocked = await this.getMaxTime();
          if (maxTimeLocked) {
            this.timeLocked.archiveMaxTime = maxTimeLocked.secoundsLocked;
          }
          try {
            await axios(this.ajaxBoilerplate("post", "remove-lock", null));
            const tl = this.calculateTimeLocked(this.lockCreated);

            this.timeLocked.days = tl.days;
            this.timeLocked.hours = tl.hours;
            this.timeLocked.minutes = tl.minutes;
            this.timeLocked.seconds = tl.seconds;
            this.timeLocked.onlySecounds = tl.onlySecounds;

            this.alertSuccess("Successfully removed lock");
            this.$emit("isReleased", this.timeLocked);
          } catch (error) {
            console.log(error);
            this.alertError("Error removing lock");
          }
        } else {
          try {
            const result = await axios(
              this.ajaxBoilerplate("post", "update-lock", null)
            );
            this.SET_RELEASE_TRIED(result.data.lastTry);
          } catch (error) {
            console.log(error);
            this.alertError("Error updating lock");
          }
        }
      }, 3000);
    },
    async getMaxTime() {
      try {
        const result = await axios(
          this.ajaxBoilerplate("get", "/archive-lock/max", null)
        );
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.flip-card {
  font-size: 1.9rem !important;
}

.flip-card__back::before,
.flip-card__top {
  color: #f3a723 !important;
}

.flip-card__bottom,
.flip-card__back-bottom {
  color: #ffca6e !important;
}

.spinner-wrapper {
  margin-top: -50px;
}
</style>