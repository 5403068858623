<template>
  <div>
    <div v-if="showData">
      <div class="form-row">
        <div class="col-12">
          <label for="timeBetweenTrying">Time between each try in hours</label>
          <input
            id="timeBetweenTrying"
            type="number"
            min="1"
            v-model="timeBetween"
            class="form-control"
            placeholder="Hours"
          />
        </div>
      </div>

      <button
        type="button"
        v-on:click="saveSettings()"
        class="btn btn-outline-secondary btn-lg btn-block mt-5"
      >
        Save
      </button>

      <button
        v-on:click="removeLock()"
        type="button"
        class="btn btn-outline-danger btn-lg btn-block mt-5"
        v-bind:disabled="!sLockKey"
      >
        <span v-if="removeLockClicks === 0">Remove lock</span>
        <span v-if="removeLockClicks === 1">Are you sure?</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Settings",
  data() {
    return {
      timeBetween: 24,
      showData: false,
      removeLockClicks: 0
    };
  },
  watch: {
    dataReady: function (val) {
      if (this.showData === false && val === true) {
        this.fetchData();
        this.showData = true;
      }
    },
  },
  created() {
    if (this.dataReady) {
      this.fetchData();
      this.showData = true;
    }
  },
  computed: {
    ...mapGetters(["sLockKey", "sUserSettingsTimeBetweenTrying", "dataReady"]),
  },
  methods: {
    ...mapMutations(["REMOVE_LOCK_KEY", "SET_SETTINGS_TIME_BETWEEN_TRYING"]),
    fetchData() {
      this.timeBetween = this.sUserSettingsTimeBetweenTrying;
    },
    async saveSettings() {
      if (parseInt(this.timeBetween) >= 1) {
        await this.checkSession();
        try {
          const result = await axios(
            this.ajaxBoilerplate("post", "save-settings", {
              timeBetweenTrying: parseInt(this.timeBetween),
            })
          );
          this.SET_SETTINGS_TIME_BETWEEN_TRYING(this.timeBetween);
          this.alertSuccess("Successfully saved settings");
        } catch (error) {
          console.log(error);
        }
      } else {
        this.alertError("Minimum 1 hour!");
      }
    },
    async removeLock() {
      await this.checkSession();
      if(this.removeLockClicks === 0) {
        this.removeLockClicks = 1;
        return;
      }
      this.removeLockClicks = 0;
      try {
        await axios(this.ajaxBoilerplate("post", "remove-lock", null));
        this.REMOVE_LOCK_KEY();
        this.alertSuccess("Successfully removed lock");
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "grulet",
          title: "Error removing lock",
          text: "",
          position: "top",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
</style>